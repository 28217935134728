
export const getFormattedNumber = (number, decimals, nullValueText) => {
  if (!number) {
    return nullValueText || '';
  }
  return parseFloat(parseFloat(number).toFixed(decimals)).toLocaleString('en-US',  { minimumFractionDigits: decimals });
}

export const valueOrNA = (value, suffix) => {
  return (value !== undefined && value !== '') ? suffix ? (value + suffix) : value : "N/A";
}

export const formatNumber = (number) => {
  if (!number) {
    return '';
  }
  if (parseFloat(number) > 1000) {
    return getFormattedNumber(number, 0);
  }

  if (parseFloat(number) > 100 && (parseFloat(number) <= 1000)) {
    return getFormattedNumber(number, 1);
  }

  return  getFormattedNumber(number, 2);
}


export const roundNumber = (number) => {
  if (!number) {
    return number;
  }
  if (parseFloat(number) > 1000) {
    return parseFloat(parseFloat(number).toFixed(0));
  }

  if (parseFloat(number) > 100 && (parseFloat(number) <= 1000)) {
    return parseFloat(parseFloat(number).toFixed(1));
  }

  return parseFloat(parseFloat(number).toFixed(2));
}

export const randomKey = (length) => {
  return Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);
}

export const numericStyle =
  {
    alignment: { vertical: 'middle', horizontal: 'right' },
    numFmt: '#,##0.00'
  }